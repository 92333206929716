.events-calendar-container {
    margin: 0;
    /* padding: 30px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(135deg, #1a4b7c, #2c6fad);
    background-size: 200% 200%;
    animation: gradientAnimation 10s ease infinite;
    position: relative;
    overflow: hidden;
    color: #ffffff;
}

/* Snowflake Animation */
.snowflake {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    top: -10px;
    z-index: 1;
    pointer-events: none;
    animation: fall linear infinite;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

@keyframes fall {
    to {
        transform: translateY(100vh) rotate(360deg);
    }
}

.christmas-tree-container {
    position: absolute;
    bottom: -20px;
    right: -50px;
    /* left: 0; */
    display: flex;
    /* justify-content: space-between; */
    z-index: 0;
}

.christmas-tree {
    width: 280px;
    /* 350px * 0.8 */
    height: 560px;
    /* 700px * 0.8 */
}

@media (max-width: 768px) {
    .christmas-tree {
        display: none;
    }
}

.christmas-garlend {
    width: 500px;
    height: 500px;
}

.christmas-wreath {
    position: fixed;
    top: -10px;
    left: 6%;
    transform: translateX(-43%) translateY(-5%);
    width: 240px;
    height: 360px;
    z-index: 0;
}

.event-calender-container-center {
    position: absolute;
    top: 20%;
    left: 8%;
    width: 85%;
}

.events-calendar-header {
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}

.events-calendar-table {
    margin-top: 20px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    position: relative;
}

.events-calendar-table th,
.events-calendar-table td {
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.events-calendar-table thead th {
    background-color: #8b0000;
    color: #fffacd;
    border: none;
}

.events-calendar-table tbody tr {
    transition: background-color 0.3s;
}

.events-calendar-table tbody tr:hover {
    background-color: rgba(34, 139, 34, 0.5);
}

/* Animated background gradient */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}