.points-container {
    width: 100%;
    padding: 20px;
}

.points-container-header {
    margin-bottom: 40px;
}

.points-row {
    margin-left: 15%;
    margin-right: 5%;
    margin-bottom: 10px;
    /* Space between rows */
}

.points-column {
    padding: 0 10px;
}

.points-item {
    display: flex;
}

.li-promoter {
    margin-right: 10px;
    margin-top: 0px;
    color: red;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .points-container {
        padding: 0px;
    }

    .points-row {
        margin-left: 10%;
        margin-right: 5%;
        margin-bottom: 0px;
        /* Space between rows */
    }

    .points-column {
        width: 100%;
        padding: 0 15px;
    }
}