.MissionStatementComponentCard-body {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px;
    text-align: left;
}

.MissionStatementComponentCard-image {
    width: 150px;
    object-fit: cover;
}

.MissionStatementComponentCard-title {
    font-size: 1.5em;
    margin: 16px 0;
}

.MissionStatementComponentCard-list {
    list-style-type: none;
    padding: 0;
}

.MissionStatementComponentCard-list li {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.li-promoter {
    margin-right: 8px;
}

.MissionStatementComponentCard-grid {
    display: grid;
    gap: 20px;
    margin: 50px;
}

/* Media queries for responsiveness */
@media (max-width: 767px) {
    .MissionStatementComponentCard-grid {
        grid-template-columns: 1fr;
        /* 1 column for mobile devices */
        margin: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .MissionStatementComponentCard-grid {
        grid-template-columns: 1fr;
        /* 1 column for tablets */
    }
}

@media (min-width: 1025px) {
    .MissionStatementComponentCard-grid {
        grid-template-columns: repeat(var(--columns, 2), 1fr);
        /* Default to input columns for laptops */
    }
}