.text-accordion-body {
    background-color: #F8F8F8;
    /* padding-bottom: 50px; */
}

.text-accordion-wrapper {
    padding-left: 20%;
    padding-right: 20%;
}

.accordion-item {
    margin-bottom: 20px;
    border: none;
    /* Adds 20px gap between accordion items */
}

.accordion-points {
    list-style-type: none;
    padding-left: 0;
}

.accordion-points li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.li-promoter {
    margin-right: 10px;
}

.pdf-downloads {
    margin-left: auto;
    display: flex;
    gap: 10px;
    color: red;
}

.download-icon {
    cursor: pointer;
    font-size: 1.2em;
}
