.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
  color: white;
  padding: 20px;
  text-align: center;



  display: flex;
  /* Use flexbox for alignment */
  flex-direction: column;
  /* Stack children vertically */
  justify-content: flex-end;
  /* Align children to the bottom */

}

.c-slide-title {
  font-size: 3.5rem;
}


.main-carousel-image {
  height: 70vh !important
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .c-slide-title {
    font-size: 2rem;
    /* Adjust title font size for mobile view */
  }

  .c-slide-desc {
    display: none;
    /*font-size: 0.7rem;*/
    /* Adjust description font size for mobile view */
  }

  .main-carousel-item {
    height: 300px;
  }

  .main-carousel-image {
    height: 100%;
    width: 100% !important;
    object-fit: cover !important;
  }

  .main-carousel-item {
    height: 300px;
  }

}

/* Media query for tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .c-slide-title {
    font-size: 2.5rem;
    /* Adjust title font size for tablets */
  }

}