.margin-0 {
    margin: 0px;
}


.red-background {
    background: #ff8282;
}

.stage-image-container {
    width: 100%;
  }

  .stage-container {
    position: relative; /* Ensure the container is positioned relative to its containing element */
    margin: 0;
    max-height: fit-content;
  }

  .stage-text-container {
    position: relative; /* Position the text container absolutely */
    background-color: white; /* Set a white background color */
    padding: 20px; /* Add padding to the container */
    z-index: 5;
    width: 80%;
    margin: 0 10%;
    padding: 20px;

    border-radius: 20px;

    opacity: 0; /* Initially set opacity to 0 */
    animation: fadeIn 1s forwards; /* Apply the fade-in animation */

    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /*  horizontal-offset  vertical-offset blur-radius color*/
}

@media (max-width: 767px) {
    .stage-text-container {
      margin: 0; /* Remove margin for mobile screens */
      border-radius: 0; /* Remove rounded corners for mobile screens */
      width: 100%;
    }
  }

  .img-center-stage {
    width: 100%;
    height: 500px;
    object-fit: cover;
    position: relative;
  }
  
  @keyframes fadeIn {
    from {
        top: 0px; /* Start position */
        opacity:0.7; /* Start opacity */
    }
    to {
        top: -150px; /* End position */
        opacity: 1; /* End opacity */
    }
}