.text-caption-div {
  margin-left: 40%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left !important;
  background-color: rgba(0, 0, 0, 0.145);
  /* Semi-transparent black background */
  backdrop-filter: blur(10px);
  /* Add blur effect */
  width: 40%;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .text-caption-div {
    /* Set margin-left to 0% for mobile devices */
    margin-left: 0%;

    width: 80%;
    /* Optionally, set width to 100% to occupy the full width */

  }

  .title-sh-car {
    font-size: 1.3rem !important;
  }

  .item-sh-car {
    height: 300px !important;
  }

  .sh-carousel-image {
    height: 300px !important;
    object-fit: cover !important;
  }

  .subtitle-sh-car {
    font-size: 0.8rem;
  }

}

.title-sh-car {
  font-size: 2.5rem;
  top: 50%;
  margin-left: 6%;
}

.subtitle-sh-car {
  margin-top: 1%;
  margin-left: 6%;
}

.showcase-carousel {
  padding: 0 5%;
}

.item-sh-car {
  border-radius: 20px;
  height: '550px'
}

.sh-carousel-image {
  height: 550px;
  object-fit: cover;
}


.learn-more-link-sh-car {
  color: white;
  text-decoration: none;
  position: relative;
  margin-left: 70%;
  margin-right: auto;
}


.learn-more-link-sh-car::after {
  content: '';
  display: block;
  margin-top: 5px;
  margin-left: 70%;
  margin-right: auto;
  max-width: 110px;
  width: 0;
  height: 2px;
  background-color: white;
  /* Change color as needed */
  transition: width 0.3s ease;
  /* Add transition for width */
}

.learn-more-link-sh-car:hover::after {
  width: 100%;
  /* Expand underline on hover */
}

.learn-more-link-sh-car:hover .arrow-icon {
  opacity: 1;
  /* Show arrow icon on hover */
  margin-left: 2%;
  /* Adjust margin for smooth appearance */
}

.btn-explore-div {
  display: block;
}