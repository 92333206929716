/* General reset for centering the entire body */
/* Apply styles only within the .contact-card-container */
/* Only apply these styles when the QRContactCard is rendered */
.contact-card-background {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(135deg, #ff8484, #86bcff);
    background-size: 200% 200%;
    animation: gradientAnimation 6s ease infinite;
}

.image-container-qr-contact {
    width: 580px;
    height: 300px;

    position: relative;
    /* Allows positioning of the img inside */
    border-radius: 12px;
    /* Rounded edges */
    overflow: hidden;
    background-color: white;
    /* Ensures that the image stays within the rounded edges */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
}

.contact-image-qr-contact {
    position: absolute;
    /* Position the image inside the container */
    top: 0;
    left: 0;
    width: 100%;
    /* Make the image cover the container */
    height: 100%;
    /* Cover the container */
    object-fit: contain;
    /* Ensure the image covers the container without distortion */
}

@media (max-width: 768px) {

    /* Adjust the max-width as needed for your design */
    .contact-card-background {
        padding: 20px;
        /* Add some padding for smaller screens */
        height: auto;
        /* Allow height to adjust based on content */
    }

    .image-container-qr-contact {
        margin-top: 100px;
        width: 380px;
        /* Full width on mobile */
        height: 205px;
        /* Allow height to adjust based on content */
        margin-bottom: 50px;
    }

    .contact-image-qr-contact {
        /* No changes needed here, as the image will adapt */
    }
}

/* Animated background gradient */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Centered card container */
.card-container-qr-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* Card styling */
.card-qr-contact {
    background-color: white;
    border-radius: 16px;
    padding: 24px;
    max-width: 350px;
    text-align: center;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    animation: cardAppear 0.5s ease-out;
}

/* Card appearing animation */
@keyframes cardAppear {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.seperator-div {
    width: 5px;
    height: 10px;
}

/* Card title and other text styling */
.card-qr-contact h2 {
    font-size: 1.75rem;
    margin: 0.5rem 0;
    color: #333;
}

.card-qr-contact .title {
    font-size: 1.25rem;
    color: #777;
    margin-bottom: 1rem;
}

.card-qr-contact p {
    margin: 0.5rem 0;
    color: #555;
}

.card-qr-contact a {
    color: #007BFF;
    text-decoration: none;
}

.card-qr-contact a:hover {
    text-decoration: underline;
}

/* Button container for both actions */
.button-container-qr-contact {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-qrct {
    margin: 10px;
    color: white;
    background-color: #5184bd;
}


/* Media query for responsiveness */
@media (max-width: 600px) {
    .card-qr-contact {
        max-width: 280px;
        padding: 20px;
    }

    .info-qr-contact {
        padding: 8px 12px;
    }
}