/* Customize the title */
.crt-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    /* Change the color as needed */
    margin-top: 10%;
    /* Add 10% left margin */
    color: #30557d;
    font-size: calc(var(--ms2-ratio) * 1rem);
    font-weight: 500px;
}

/* Customize the description */
.crt-description {
    font-size: 16px;
    color: #666;
    /* Change the color as needed */
    /* Add 10% left margin */
}

/* Customize the image container */
.crt-image-container {
    margin-top: 20px;

    display: flex;
    justify-content: flex-end;
    /* Right align items */

}

.crt-image-container-wrapper {
    display: flex;
    justify-content: flex-end;
    /* Right align items */
}

@media (max-width: 767px) {

    .crt-image-container,
    .crt-image-container-wrapper {
        justify-content: center;
        /* Center align items on mobile screens */
    }
}


/* Customize the image */
.crt-img-fluid {

    height: 430px;
    width: 300px;
    /* Set maximum height to 100% */
    object-fit: contain;
    /* Maintain aspect ratio */
    aspect-ratio: 4 / 3;
    /* Set aspect ratio to 4:3 */
    margin-right: auto;
    margin-left: auto;
    /* Add 10% right margin */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.crt-row-container {
    margin: 5% 0;
}

.crt-image-info {
    margin-right: 20%;
    margin-left: 10%;
}

.faint-horizontal-line {
    border-top: 1px solid rgba(0, 0, 0, 0.766);
    /* Faint horizontal line */
    margin-left: 10%;
    /* 10% left margin */
    margin-right: 10%;
    /* 10% right margin */
}